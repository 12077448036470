// plugins/getInitials.ts
export default defineNuxtPlugin(() => {
  const getInitials = (name: string) => {
    const words = name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    return initials.join("");
  };

  return {
    provide: {
      getInitials,
    },
  };
});
