import revive_payload_client_92Y17P24u3 from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__fx743ar2vytubxe7xev2ensv4a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Li9qDdlVD7 from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__fx743ar2vytubxe7xev2ensv4a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iyA5kdqsGj from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__fx743ar2vytubxe7xev2ensv4a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ux5psFNSyl from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__fx743ar2vytubxe7xev2ensv4a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OfSW1sgn8q from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__fx743ar2vytubxe7xev2ensv4a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_e7sGiwNAl6 from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__fx743ar2vytubxe7xev2ensv4a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_gAd07EhE34 from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__fx743ar2vytubxe7xev2ensv4a/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_6oR5YH4yN1 from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__fx743ar2vytubxe7xev2ensv4a/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_jvlnNp1rXc from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@22.5.1_eslint@9.9.1_jiti@1.21.6__ioredis@5.4.1__fx743ar2vytubxe7xev2ensv4a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_SmyQ9iHVcl from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.12_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_vue-router@4.4.3_vue@3._zauqklnasomewtcyfn5acc4xvm/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_Kc3PIwNw3z from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.12_ioredis@5.4.1_magicast@0.3.5_rollup@4.21.2_vue-router@4.4.3_vue@3._zauqklnasomewtcyfn5acc4xvm/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import slideovers_aB6o0KfUQK from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.5_rollup@4.21.2_vite@5.4.2_@types+node@22.5.1_less@4.2.0_terser@_2iacyexl677w4vuxsa5pigku5i/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_qnacljR4SH from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.5_rollup@4.21.2_vite@5.4.2_@types+node@22.5.1_less@4.2.0_terser@_2iacyexl677w4vuxsa5pigku5i/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_YRaZT9NZQA from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/@nuxt+ui@2.18.4_magicast@0.3.5_rollup@4.21.2_vite@5.4.2_@types+node@22.5.1_less@4.2.0_terser@_2iacyexl677w4vuxsa5pigku5i/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_g3202afUA8 from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.5_rollup@4.21.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_xyMeFRRsIq from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/node_modules/.pnpm/@nuxt+icon@1.5.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.2_@types+node@22.5.1_less@4.2.0_terser_hlfrttyxumxcqp4oznjq3dos7m/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import getInitials_zpxNPtOw0A from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/plugins/getInitials.ts";
export default [
  revive_payload_client_92Y17P24u3,
  unhead_Li9qDdlVD7,
  router_iyA5kdqsGj,
  payload_client_ux5psFNSyl,
  navigation_repaint_client_OfSW1sgn8q,
  check_outdated_build_client_e7sGiwNAl6,
  view_transitions_client_gAd07EhE34,
  chunk_reload_client_6oR5YH4yN1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jvlnNp1rXc,
  composition_SmyQ9iHVcl,
  i18n_Kc3PIwNw3z,
  slideovers_aB6o0KfUQK,
  modals_qnacljR4SH,
  colors_YRaZT9NZQA,
  plugin_client_g3202afUA8,
  plugin_xyMeFRRsIq,
  getInitials_zpxNPtOw0A
]