<script setup>

useHead({
  htmlAttrs: {
    lang: "tr",
  },
  charset: "utf-8",
  title: "Uygunsecim.com",
  titleTemplate: (title) =>
    title !== "Uygunsecim.com - Alışverişin için uygun seçimi bul!"
      ? `${title} · Uygun Seçim`
      : title,
  meta: [
    {
      name: "description",
      content:
        "Uygunsecim.com alışverişin için en uygun fiyatı bulmana yardımcı olur✨",
    },
    // {
    //   property: "og:image",
    //   content: "https://movies.nuxt.space/social-card.png",
    // },
    // { name: "twitter:card", content: "summary_large_image" },
    // { name: "twitter:site", content: "@nuxt_js" },
    // { name: "twitter:creator", content: "@nuxt_js" },
  ],
  link: [
    // Head bölümüne eklenecek link etiketleri
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
    },
    {
      rel: "stylesheet",
      href: "https://cdn.linearicons.com/free/1.0.0/icon-font.min.css",
    },
    {
      rel: "stylesheet",
      href: "https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.3/css/bootstrap.min.css",
    }
  ],
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
