import { default as _91product_935P9Y0l3xYEMeta } from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/[category]/[product].vue?macro=true";
import { default as _91slug_93VL3Sr1ZFLfMeta } from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/[slug].vue?macro=true";
import { default as bilgilerimzVnAu6U9L2Meta } from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/hesabim/bilgilerim.vue?macro=true";
import { default as favorilerim9A8timRUzBMeta } from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/hesabim/favorilerim.vue?macro=true";
import { default as indexmVQjlsY06HMeta } from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/index.vue?macro=true";
import { default as search8hHjf9K5GHMeta } from "/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/search.vue?macro=true";
export default [
  {
    name: "category-product",
    path: "/:category()/:product()",
    component: () => import("/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/[category]/[product].vue").then(m => m.default || m)
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/[slug].vue").then(m => m.default || m)
  },
  {
    name: "hesabim-bilgilerim",
    path: "/hesabim/bilgilerim",
    component: () => import("/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/hesabim/bilgilerim.vue").then(m => m.default || m)
  },
  {
    name: "hesabim-favorilerim",
    path: "/hesabim/favorilerim",
    component: () => import("/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/hesabim/favorilerim.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/var/www/api.fiyatavantaji.com/uygunsecim/frontend/pages/search.vue").then(m => m.default || m)
  }
]